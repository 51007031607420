import {seoURL} from '@halp/util';
import {Domains} from './domains';
import {Path} from './path';

export const ADMIN_PATHS = {
	index: new Path('/', Domains.admin),
	qualification: {
		index: new Path('/qualification', Domains.admin),
		show: new Path((id: number) => {
			return `/qualification/${id}`;
		}, Domains.admin),
	},
	users: {
		index: new Path('/users', Domains.admin),
	},
	coaches: {
		index: new Path('/coaches', Domains.admin),
		show: new Path((id: number | string, seo?: string | null) => {
			return `/coaches/show/${id}${seoURL(id, seo)}`;
		}, Domains.admin),
	},
	campaigns: {
		index: new Path('/campaigns', Domains.admin),
		show: new Path((id: number | string, seo?: string | null) => {
			return `/campaigns/${id}${seoURL(id, seo)}`;
		}, Domains.admin),
	},
	partners: {
		index: new Path('/partners', Domains.admin),
		show: new Path((id: number | string, seo?: string | null) => {
			return `/partners/${id}${seoURL(id, seo)}`;
		}, Domains.admin),
	},
	coaching: {
		index: new Path('/coaching', Domains.admin),
	},
};
